@import "global";

.title {
  color: $graphite;
  font-weight: 400;
}

.App {
  font-size: calc(10px + 2vmin);
  background-color: black;
  height: 100%;
  display:flex;
  color: whitesmoke;
}

html, body, #root {
  height: 100%;
}

.logo {
  position: fixed;
  bottom: 10px;
  left: 10px;
}

body {
  background-color: black !important;
  overflow-y: scroll;
}


.no-cursor, .no-cursor:hover {
  cursor: none !important;
}


/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #ff913a #d4cece;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
  }



  *::-webkit-scrollbar-thumb {
    background-color: #ff913a;
    border-radius: 4px;
    border: 2px solid #000000;
  }
